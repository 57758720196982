import FeatureItem from "./PackageFeature";

function PackageFeatureList({ features }) {
    return (
      <ul className="flex gap-4">
        <div className="p-4">
          {features.slice(0, Math.ceil(features.length / 2)).map((text) => (
            <FeatureItem key={text} text={text} />
          ))}
        </div>
        <div className="p-4">
          {features.slice(Math.ceil(features.length / 2)).map((text) => (
            <FeatureItem key={text} text={text} />
          ))}
        </div>
      </ul>
    );
  }
  
  export default PackageFeatureList;