import {useEffect} from 'react';
import { Outlet } from 'react-router-dom'
import useAuth from "./hooks/useAuth";


function App() {
  const { fetchUser } = useAuth();

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <Outlet />
  );
}

export default App;
