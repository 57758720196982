import React, { useState } from 'react';


function SettingsModal({ verbosityProps,transcriptionDelayProps,copilotTemperatureProps,isOpen, onClose, onSaveSettings }) {
  const [verbosity, setVerbosity] = useState(verbosityProps);
  const [transcriptionDelay, setTranscriptionDelay] = useState(transcriptionDelayProps);
  const [copilotTemperature, setCopilotTemperature] = useState(copilotTemperatureProps);  

  if (!isOpen) return null;

  // Added console.log to track state changes.
  console.log('Current states:', { verbosity, transcriptionDelay, copilotTemperature });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
      <div className="bg-white p-4 rounded-lg w-1/2 text-center justify-center">
        <h2 className="text-xl font-semibold leading-6 text-gray-900 mb-4 border-b pb-4">Settings</h2>

        <div className="mb-6">
          <label htmlFor="verbosity" className="block text-sm font-bold mb-2">Verbosity</label>
          <p className="text-gray-600 text-sm mb-4">The length and complexity of your Copilot responses</p>
          <div id="verbosity" className="flex space-x-2 justify-center" role="group" aria-label="Verbosity options">
            {['concise', 'default', 'lengthy'].map(option => (
              <React.Fragment key={option}>
                <input type="radio" className="sr-only" name="verbosity" id={option} autoComplete="off" checked={verbosity === option} onChange={() => {
                  console.log('Setting verbosity to:', option);
                  setVerbosity(option);
                }} />
                <label className={`px-4 py-2 border border-transparent rounded text-white cursor-pointer capitalize ${verbosity === option ? ' bg-blue-600' : 'bg-gray-500'}`} htmlFor={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</label>
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label htmlFor="transcriptionDelay" className="block text-sm font-bold mb-2">Transcription Delay</label>
          <p className="text-gray-600 text-sm mb-4">Delay between the time when audio is received and when the transcription process begins</p>
          <div id="transcriptionDelay" className="flex space-x-2 justify-center" role="group" aria-label="Transcription delay options">
            {['low', 'default', 'high'].map(option => (
              <React.Fragment key={option}>
                <input type="radio" className="sr-only" name="transcriptionDelay" id={option} autoComplete="off" checked={transcriptionDelay === option} onChange={() => {
                  console.log('Setting transcriptionDelay to:', option);
                  setTranscriptionDelay(option);
                }} />
                <label className={`px-4 py-2 border border-transparent rounded text-white cursor-pointer capitalize ${transcriptionDelay === option ? ' bg-blue-600' : 'bg-gray-500'}`} htmlFor={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</label>
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label htmlFor="copilotTemperature" className="block text-sm font-bold mb-2">Copilot Temperature</label>
          <p className="text-gray-600 text-sm mb-4">
            Temperature in Copilot controls how cool or hot the creative process is. High temperature is like adding a dash of randomness, while low temperature is like sticking to what it knows really well
          </p>
          <div id="copilotTemperature" className="flex space-x-2 justify-center" role="group" aria-label="Copilot temperature options">
            {['lowTemp', 'default', 'highTemp'].map(option => (
              <React.Fragment key={option}>
                <input type="radio" className="sr-only" name="copilotTemperature" id={option} autoComplete="off" checked={copilotTemperature === option} onChange={() => {
                  console.log('Setting copilotTemperature to:', option);
                  setCopilotTemperature(option);
                }} />
                <label className={`px-4 py-2 border border-transparent rounded text-white cursor-pointer capitalize ${copilotTemperature === option ? ' bg-blue-600' : ' bg-gray-500'}`} htmlFor={option}>{option.replace(/Temp$/, '')}</label>
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="flex justify-end mt-4 border-t pt-4">
          <button onClick={onClose} className="mr-2 px-4 py-2 bg-gray-200 rounded">Close</button>
          <button onClick={() => {
            console.log('Saving Settings with states:', { verbosity, transcriptionDelay, copilotTemperature });
            onSaveSettings(copilotTemperature,transcriptionDelay,verbosity);
          }} className="px-4 py-2 bg-blue-600 text-white rounded">Save</button>
        </div>


      </div>
    </div>
  );
}

export default SettingsModal;
