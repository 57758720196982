import React, { useEffect, useState, useRef } from 'react';
import {
  SettingsModal,
  KnowledgeModal,
  BillingModal,
  StartInterviewModal,
  GivePermissionsModal,
  UserProfile,
} from "../components";
import { toast } from "react-toastify";
import RecordRTC from "recordrtc";
import axios from "axios";
import { apiUrl } from "../api";
import useAssistant from "../hooks/useAssistant";
import usePrompt from "../hooks/usePrompt";
import RecordingTimer from "../components/RecordingTimer";
import { deductCredit, getUserDetails, getUserPaymentDetails, isValidCredit, updateRemainingCredit } from "../utils/AccountCredit";

function InterviewPage() {
  const [transcript, setTranscript] = useState([]);
  const [autoScroll, setAutoScroll] = useState(true);
  const [botResponse, setBotResponse] = useState([]);
  const [threadId, setThreadId] = useState("");
  const [assistantId, setAssistantId] = useState("");
  const [userAskQuestion, setUserAskQuestion] = useState("");
  const [verbosity, setVerbosity] = useState(
    localStorage.getItem("ver") || "default"
  );
  const [transcriptionDelay, setTranscriptionDelay] = useState(
    localStorage.getItem("tran") || "default"
  );
  const [copilotTemperature, setCopilotTemperature] = useState(
    localStorage.getItem("tem") || "default"
  );

  const leftPanelRef = useRef(null);
  const centerPanelRef = useRef(null);

  const [meetingStartedTimer, setMeetingStartedTimer] = useState(false);
  const [timer, setTimer] = useState('00:00:00');
  const [showTimer, setShowTimer] = useState(false);
  const [timerRunning, setTimerRunning] = useState(false);

  const { createAssistant, createThread, assistantChat } = useAssistant();
  const { formatPrompt } = usePrompt();

  useEffect(() => {
    let intervalId;

    if (meetingStartedTimer) {
      const startTime = Date.now();
      intervalId = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const hours = Math.floor((elapsedTime / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((elapsedTime / (1000 * 60)) % 60);
        const seconds = Math.floor((elapsedTime / 1000) % 60);

        const formattedTimer = [
          hours.toString().padStart(2, '0'),
          minutes.toString().padStart(2, '0'),
          seconds.toString().padStart(2, '0'),
        ].join(':');

        const availableCredit = localStorage.getItem('availableCredit');
        const expiredCredit = availableCredit ? isValidCredit(parseInt(availableCredit, 10), formattedTimer) : false;
        console.log('⚠️ Checking if credit expired:', expiredCredit);
        if (!expiredCredit) {
          clearInterval(intervalId);
          setTimerRunning(false);
          setTimer('00:00:00');
          const { userId } = getUserDetails();
          updateRemainingCredit(userId, 0);
          stopCreditChecker();
          alert('Your credit has expired');
        }

        setTimer(formattedTimer);
        console.log('⏲️ Timer updated:', formattedTimer);

      }, 1000);

      setTimerRunning(true);
    }

    if (!showTimer) {
      clearInterval(intervalId);
      setTimerRunning(false);
      setTimer('00:00:00');
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setTimerRunning(false);
      }
    };
  }, [meetingStartedTimer]);

  useEffect(() => {
    if (meetingStartedTimer && !showTimer && timerRunning) {
      console.log('🛑 Stopping timer and saving current time');
      localStorage.setItem('savedTimer', timer);
      setTimerRunning(false);
    }
  }, [showTimer, meetingStartedTimer, timer, timerRunning]);

  useEffect(() => {
    if (leftPanelRef.current && autoScroll) {
      leftPanelRef.current.scrollTop = leftPanelRef.current.scrollHeight;
    }
  }, [transcript, autoScroll]);

  useEffect(() => {
    if (centerPanelRef.current && autoScroll) {
      centerPanelRef.current.scrollTop = centerPanelRef.current.scrollHeight;
    }
  }, [botResponse, autoScroll]);

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const handleOpenSettingsModal = () => {
    console.log('⚙️ Opening Settings Modal');
    setIsSettingsModalOpen(true);
  };
  const handleCloseSettingsModal = () => {
    console.log('⚙️ Closing Settings Modal');
    setIsSettingsModalOpen(false);
  };

  const handleSaveSettings = (tem, tran, ver) => {
    console.log('⚙️ Saving Settings:', { tem, tran, ver });
    localStorage.setItem("ver", ver);
    localStorage.setItem("tem", tem);
    localStorage.setItem("tran", tran);
    setCopilotTemperature(tem);
    setTranscriptionDelay(tran);
    setVerbosity(ver);

    setIsSettingsModalOpen(false);
  };

  const [isKnowledgeModalOpen, setIsKnowledgeModalOpen] = useState(false);
  const handleOpenKnowledgeModal = () => {
    console.log('📚 Opening Knowledge Modal');
    setIsKnowledgeModalOpen(true);
  };
  const handleCloseKnowledgeModal = () => {
    console.log('📚 Closing Knowledge Modal');
    setIsKnowledgeModalOpen(false);
  };
  const handleSaveKnowledge = () => {
    console.log('📚 Saving Knowledge');
    setIsKnowledgeModalOpen(false);
  };

  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const handleOpenBillingModal = () => {
    console.log('💳 Opening Billing Modal');
    setIsBillingModalOpen(true);
  };
  const handleCloseBillingModal = () => {
    console.log('💳 Closing Billing Modal');
    setIsBillingModalOpen(false);
  };

  const handleSaveBilling = () => {
    console.log('💳 Saving Billing Info');
    setIsBillingModalOpen(false);
  };

  const [isStartInterviewModalOpen, setIsStartInterviewModalOpen] = useState(false);
  const handleOpenStartInterviewModal = () => {
    console.log('🎤 Opening Start Interview Modal');
    const activePlan = JSON.parse(localStorage.getItem('activePlan'));
    if (!activePlan || !activePlan[0] || activePlan[0].credit <= 0) {
      toast.error("You have insufficient credit to start the meeting");
      return;
    }
    setIsStartInterviewModalOpen(true);
  };

  const handleCloseStartInterviewModal = () => {
    console.log('🎤 Closing Start Interview Modal');
    setIsStartInterviewModalOpen(false);
  };

  const handleSaveStartInterview = async (data) => {
    console.log("🎤 Data received in interview page", data);

    const prompt = formatPrompt(data, copilotTemperature, verbosity, transcriptionDelay);
    console.log('🎤 Formatted prompt:', prompt);

    try {
      const assistantResponse = await createAssistant(prompt, data.knowledgeDocs);
      console.log('🎤 Assistant created with ID:', assistantResponse);
      setAssistantId(assistantResponse);
    } catch (error) {
      console.error('🎤 Error creating assistant:', error);
      toast.error('Meeting creation failed, please try again!');
      return;
    }

    try {
      const threadResponse = await createThread();
      console.log('🎤 Thread created with ID:', threadResponse);
      setThreadId(threadResponse);
    } catch (error) {
      console.error('🎤 Error creating thread:', error);
    }

    setIsStartInterviewModalOpen(false);
    setIsGivePermissionsModalOpen(true);
  };

  const [isGivePermissionsModalOpen, setIsGivePermissionsModalOpen] = useState(false);
  const handleCloseGivePermissionsModal = () => {
    console.log('🛡️ Closing Give Permissions Modal');
    setIsGivePermissionsModalOpen(false);
  };
  const onSaveGivePermissions = () => {
    console.log('🛡️ Saving Give Permissions');
    setIsGivePermissionsModalOpen(false);
  };

  const updateTranscript = (user_input) => {
    console.log('💬 Updating Transcript with:', user_input);
    setTranscript((t) => [...t, user_input]);
  };
  const updateBotResponse = (response, time) => {
    console.log('🤖 Updating Bot Response with:', response);
    setBotResponse((b) => [...b, { response, time }]);
  };

  let audioContext, sourceNode, processorNode;
  let isRecording = false;
  let silenceStart = 0;
  const silenceThreshold = 3000;
  let recordRTC; // Declaring recordRTC globally
  let startTime;
  let endTime;

  const shouldSendToBackend = useRef(true);
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [meetingPaused, setMeetingPaused] = useState(false);
  const [isListeningStarted, setIsListeningStarted] = useState(false);
  const [isStreamingStarted, setIsStreamingStarted] = useState(false);
  const [stream, setStream] = useState(null);

  function calculateTimeTaken() {
    const timeTakenMilliseconds = endTime - startTime;
    const timeTakenSeconds = Math.round((timeTakenMilliseconds / 1000) * 100) / 100;
    console.log("⏲️ Time taken to process:", timeTakenSeconds, "seconds");
    return timeTakenSeconds;
  }

  const audioDataPostToBackend = async (blob) => {
    try {
      if (!blob || !(blob instanceof Blob)) {
        console.error("Error: Invalid or missing Blob object");
        return;
      }

      startTime = performance.now();

      const formData = new FormData();
      formData.append("audio", blob, "audio.mp3");
      formData.append("threadId", threadId);
      formData.append("assistantId", assistantId);

      const response = await axios.post(`${apiUrl}/upload-audio`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        const result = response.data;
        console.log('🗣️ Audio data uploaded:', result);
        endTime = performance.now();
        const timeTaken = calculateTimeTaken();
        updateTranscript(result.user_input);
        updateBotResponse(result.response, timeTaken);
      } else {
        console.error("Error uploading audio:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading audio:", error.message);
    }
  };

  const handleStartScreenShare = async () => {
    try {
      setMeetingStarted(true);
      setIsListeningStarted(true);
      setIsStreamingStarted(true);

      const mediaStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      toast.success("Meeting AI Started!");
      mediaStream.getVideoTracks().forEach((track) => (track.enabled = false));
      setStream(mediaStream);
      handleAudioProcessing(mediaStream);
      console.log('🎥 Screen sharing started');
    } catch (error) {
      setMeetingStarted(false);
      setIsListeningStarted(false);
      setIsStreamingStarted(false);
      console.error("Error capturing audio:", error);
    }
    onSaveGivePermissions();
  };

  const stopMediaStream = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
    stopCreditChecker();
    setMeetingStarted(false);
    setIsListeningStarted(false);
    setIsStreamingStarted(false);
    setMeetingStartedTimer(false);
    setShowTimer(false);
    console.log('🛑 Media stream stopped');
  };

  function handleAudioProcessing(stream) {
    audioContext = new AudioContext();
    sourceNode = audioContext.createMediaStreamSource(stream);
    processorNode = audioContext.createScriptProcessor(4096, 1, 1);
    sourceNode.connect(processorNode);
    processorNode.connect(audioContext.destination);

    processorNode.onaudioprocess = (e) => {
      const inputData = e.inputBuffer.getChannelData(0);
      let sum = 0;

      for (let i = 0; i < inputData.length; i++) {
        sum += inputData[i] * inputData[i];
      }
      let rms = Math.sqrt(sum / inputData.length);

      const rmsThreshold = 0.01;
      if (rms > rmsThreshold && !isRecording) {
        startRecording(stream);
        silenceStart = 0;
      } else if (rms <= rmsThreshold && isRecording) {
        if (silenceStart === 0) {
          silenceStart = Date.now();
        } else if (Date.now() - silenceStart > silenceThreshold) {
          stopRecording();
          silenceStart = 0;
        }
      }
    };
  }

  function startRecording(stream) {
    if (!shouldSendToBackend.current) return;
    if (isRecording) return;
    isRecording = true;

    const options = {
      type: "audio",
      mimeType: "audio/webm",
      numberOfAudioChannels: 1,
      sampleRate: 2000,
      recorderType: RecordRTC.MediaStreamRecorder,
    };

    recordRTC = new RecordRTC(stream, options);
    recordRTC.startRecording();
    console.log("🎤 Recording started");
    setMeetingStartedTimer(true);
    setShowTimer(true);
    startCreditChecker();
  }

  async function stopRecording() {
    if (!isRecording) return;
    recordRTC.stopRecording(async () => {
      console.log("🎤 Recording stopped");
      try {
        let blob = await recordRTC.getBlob();

        if (blob) {
          console.log("🎤 Blob generated:", blob);
          await audioDataPostToBackend(blob);
        } else {
          console.error("Error generating blob: No blob data available");
        }
      } catch (error) {
        console.error("Error generating blob:", error);
      }
    });
    isRecording = false;
  }

  function pauseRecording() {
    console.log("⏸️ Before state update:", shouldSendToBackend.current);
    shouldSendToBackend.current = false;
    setMeetingPaused(true);
    setIsListeningStarted(false);
    setIsStreamingStarted(false);
    console.log("⏸️ After state update:", shouldSendToBackend.current);
    console.log("⏸️ Recording paused");
  }

  function resumeRecording() {
    shouldSendToBackend.current = true;
    setMeetingPaused(false);
    setIsListeningStarted(true);
    setIsStreamingStarted(true);
    console.log("▶️ Recording resumed");
  }

  const displayPauseBtn = !meetingPaused ? (
    <button
      onClick={pauseRecording}
      className="px-2 py-2 text-sm bg-gray-600 text-white rounded"
    >
      Pause
    </button>
  ) : (
    <button
      onClick={resumeRecording}
      className="px-2 py-2 text-sm bg-gray-600 text-white rounded"
    >
      Resume
    </button>
  );

  const handleChat = async () => {
    if (userAskQuestion === "") {
      toast.error("Please insert chat question");
      return;
    }

    if (assistantId === "" || threadId === "") {
      toast.error("Please start the meeting first");
      return;
    }
    updateTranscript(userAskQuestion);
    try {
      const res = await assistantChat(assistantId, threadId, userAskQuestion);
      updateBotResponse(res.response);
      setUserAskQuestion("");
      console.log('💬 User chat handled with response:', res.response);
    } catch (error) {
      console.error("Error handling chat:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleChat();
    }
  };

  const startCreditChecker = async () => {
    const { userId } = getUserDetails();
    const updatedUserPaymentDetails = await getUserPaymentDetails(userId);

    if (updatedUserPaymentDetails && updatedUserPaymentDetails.length > 0) {
      if (updatedUserPaymentDetails[0]?.credit > 0) {
        console.log('🟢 You have enough credit to start the meeting');
        setMeetingStartedTimer(true);
        setShowTimer(true);
        return;
      }
    }
    console.log('🔴 No payment details found or user has no credit entries');
  }

  const stopCreditChecker = () => {
    console.log('🔴 Stopping credit checker, formatted timer:', timer);
    deductCredit(getUserDetails().userId, timer);
    setMeetingStartedTimer(false);
    setShowTimer(false);
    setTimer('00:00:00');
  }

  const handleCheckboxChange = () => {
    setAutoScroll(prevState => !prevState);
    console.log('📝 Auto-scroll toggled to:', !autoScroll);
  };

  return (
    <div className="w-full bg-gray-50 rounded-lg shadow-inner flex flex-col h-screen">
      {showTimer && <RecordingTimer timer={timer} />}
      <div className="bg-white overflow-hidden m-auto w-full border-2 border-dark">
        <div className="px-4 py-2 flex justify-between items-center border-b">
          <h1 className="text-lg font-semibold">
            <img src="../logo.svg" alt="" className="h-12" />
          </h1>
          <div className="space-x-2 flex">
            <button className="px-8 py-2 m-auto">
              <label className="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" className="sr-only peer" checked={autoScroll}
                  onChange={handleCheckboxChange} />
                <div className="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-900">Auto Scroll</span>
              </label>
            </button>
            {!meetingStarted ? (
              <button
                onClick={handleOpenStartInterviewModal}
                className="px-6 text-sm bg-blue-600 rounded text-white"
              >
                Start Meeting
              </button>
            ) : (
              <button
                onClick={stopMediaStream}
                className="px-2 py-2 text-sm bg-red-600 text-white rounded"
              >
                End Meeting
              </button>
            )}
            <button
              onClick={handleOpenSettingsModal}
              className="px-6 text-sm bg-gray-300 rounded"
            >
              Settings
            </button>
            <button
              onClick={handleOpenKnowledgeModal}
              className="px-6 text-sm bg-gray-300 rounded"
            >
              Knowledge
            </button>
            <button
              onClick={handleOpenBillingModal}
              className="px-6 text-sm bg-gray-300 rounded"
            >
              Billing
            </button>
            {meetingStarted && displayPauseBtn}
            <UserProfile />
          </div>
        </div>
        <SettingsModal
          verbosityProps={verbosity}
          transcriptionDelayProps={transcriptionDelay}
          copilotTemperatureProps={copilotTemperature}
          isOpen={isSettingsModalOpen}
          onClose={handleCloseSettingsModal}
          onSaveSettings={handleSaveSettings}
        />
        <KnowledgeModal
          isOpen={isKnowledgeModalOpen}
          onClose={handleCloseKnowledgeModal}
          onSaveKnowledge={handleSaveKnowledge}
        />
        {isBillingModalOpen && (
          <BillingModal
            isOpen={isBillingModalOpen}
            handleCloseBillingModal={handleCloseBillingModal}
            onSaveBilling={handleSaveBilling}
          />
        )}
        <StartInterviewModal
          isOpen={isStartInterviewModalOpen}
          onClose={handleCloseStartInterviewModal}
          onSaveStartInterview={handleSaveStartInterview}
        />
        <GivePermissionsModal
          isOpen={isGivePermissionsModalOpen}
          onClose={handleCloseGivePermissionsModal}
          startScreenShare={handleStartScreenShare}
        />
        <div className="flex p-8 space-x-4 h-screen">
          <div
            ref={leftPanelRef}
            className="w-1/3 px-4 pb-4 bg-gray-50 rounded-lg shadow-inner border flex flex-col"
          >
            <div className="h-full">
              <div className="flex justify-between border-b pb-4 bg-gray-50 p-4 sticky top-0">
                <h2 className="font-bold m-auto" style={{ marginLeft: 0 }}>
                  💬 Meeting Stream
                </h2>
                {isListeningStarted && (
                  <span className="bg-blue-200 text-blue-500 px-2 py-1 text-center rounded">
                    Listening
                  </span>
                )}
              </div>
              <div className="h-screen overflow-auto">
                {transcript.length === 0 ? (
                  <div className="flex justify-center items-center h-screen">
                    <img src="https://dazzling-cat.netlify.app/teamlistening.png" alt="No responses yet" className="w-64 grayscale opacity-75" />
                  </div>
                ) : (
                  transcript.map((trans, index) => (
                    <p key={index} className="mt-2 bg-gray-200 p-4 rounded-lg" dangerouslySetInnerHTML={{ __html: trans }}>
                    </p>
                  ))
                )}
              </div>
              <div className="flex gap-2 mt-4 sticky bg-white border py-6 px-4 w-100" style={{ bottom: '0rem' }}>
                <input
                  value={userAskQuestion}
                  onChange={(e) => setUserAskQuestion(e.target.value)}
                  onKeyDown={handleKeyDown}
                  type="text"
                  placeholder="Write a question for a response ..."
                  className="flex-1 p-2 border border-gray-300 rounded focus:outline-none"
                />
                <button
                  className="bg-blue-600 text-white px-4 rounded"
                  onClick={handleChat}
                >
                  Ask
                </button>
              </div>
            </div>
          </div>
          <div ref={centerPanelRef} className="w-2/3 px-4 pb-4 bg-gray-50 rounded-lg shadow-inner flex flex-col border overflow-auto justify-between">
            <div className="pb-12">
              <div className="flex justify-between border-b pb-4 bg-gray-50 p-4 sticky top-0">
                <h2 className="font-bold m-auto" style={{ marginLeft: 0 }}>
                  🤖  Co-Pilot AI
                </h2>
                {isStreamingStarted && (
                  <span className="bg-blue-200 text-blue-500 px-2 py-1 text-center rounded">
                    Streaming
                  </span>
                )}
              </div>
              {botResponse.length === 0 ? (
                <div className="flex justify-center items-center h-screen m-auto">
                  <img src="https://dazzling-cat.netlify.app/airesponse.png" alt="No bot responses yet" className="w-64 grayscale opacity-75" />
                </div>
              ) : (
                botResponse.map((res, index) => {
                  const totalResponses = botResponse.length;
                  let className;
                  if (index === totalResponses - 1) {
                    className = "text-blue-600 font-bold ";
                  } else if (index === totalResponses - 2) {
                    className = "text-blue-700  font-semibold";
                  } else if (index === totalResponses - 3) {
                    className = "text-blue-900  font-normal";
                  } else {
                    className = "";
                  }

                  return (
                    <div key={index} className={`mt-4 ${className}`}>
                      <p dangerouslySetInnerHTML={{ __html: res.response }}></p>
                      <span className="text-sm text-gray-400">
                        {res.time} seconds
                      </span>
                    </div>
                  );
                })
              )}
              {isStreamingStarted && (
                <img
                  src="../writing-loading.gif"
                  alt=""
                  className="h-8 rounded-full px-2 bg-white mt-2 border"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InterviewPage;
