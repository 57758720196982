import axios from "axios";
import { apiUrl, apiKey } from "../api";
import { db } from "../config/firebase";
import { setUser } from "../features/userSlice";
import { getDoc, collection, updateDoc, doc } from "firebase/firestore";
import { useDispatch } from "react-redux";

const useAssistantFileUpload = () => {
  const dispatch = useDispatch();





  const handleFileUpload = async (selectedFile) => {
    try {
      const formData = new FormData();

      formData.append("files", selectedFile);

      const response = await axios.post(`${apiUrl}/file-upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          openai_key: apiKey,
        },
      });

      console.log(response.data);
      return response.data;
      // Handle response as needed
    } catch (error) {
      console.error("Error uploading files:", error);
      // Handle error
      throw error;
    }
  };
  

  const handleUpdateFilesInDb = async (uid, newItem) => {
    try {
      const usersCollectionRef = collection(db, "Users");
      
      const userDocRef = doc(usersCollectionRef, uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();

        // Get the array field and update it with the new item
        const updatedArray = userData.files
          ? [...userData.files, newItem]
          : [newItem];
        userData.files = updatedArray;
        localStorage.setItem("userData", JSON.stringify(userData));
        dispatch(setUser(userData));

        // Update the document with the updated array field
        await updateDoc(userDocRef, { files: updatedArray });
        console.log("Array field updated successfully");
      } else {
        console.error("User document does not exist");
      }
    } catch (error) {
      console.error("Error updating array field:", error);
    }
  };

  async function deleteFileApi(fileID) {
    try {

        const response = await axios.delete(`https://api.openai.com/v1/files/${fileID}`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`
            }
        });

        console.log('File deleted successfully:', response.data);
    } catch (error) {
        console.error('Error deleting file:', error.response ? error.response.data : error.message);
    }
}

  const handleDeleteFileFromDb = async (uid, filIdToDelete) => {
    try {
      const usersCollectionRef = collection(db, "Users");
      const userDocRef = doc(usersCollectionRef, uid);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
  
        // Filter out the file to delete from the files array
        const updatedFiles = userData.files.filter(file => file.fileId !== filIdToDelete);
        userData.files = updatedFiles;
        
        localStorage.setItem("userData", JSON.stringify(userData));
        dispatch(setUser(userData));

        // Update the document with the updated files array
        await updateDoc(userDocRef, { files: updatedFiles });
        await deleteFileApi(filIdToDelete)
        console.log("File deleted successfully");
      } else {
        console.error("User document does not exist");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

 

  return { handleFileUpload, handleUpdateFilesInDb,handleDeleteFileFromDb };
};

export default useAssistantFileUpload;
