
import { apiUrl,apiKey } from "../api";
import axios from "axios";

const useAssistant = () => {
  const createAssistantWithoutUpload = async (instructions, fileIds) => {
    try {
      const response = await axios.post(`${apiUrl}/create-assistant-without-upload`, {
        instructions: instructions,
        fileIds: fileIds
      }, {
        headers: {
          'Content-Type': 'application/json',
          'openai_key': apiKey // Replace 'YOUR_API_KEY' with your actual API key
        }
      });
      console.log(response)

      return response.data.assistantId;
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrow the error to handle it further
    }
  };

    const createAssistant = async (prompt,file_ids=[]) => {
        try {
          const endpoint = 'https://api.openai.com/v1/assistants';
      
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
            'OpenAI-Beta': 'assistants=v1'
          };
      
          const data = {
            "name": "✨ magicmeeting-interview-react",
            "instructions": prompt,
            "tools":[{"type":"code_interpreter"}, {"type":"retrieval"}],
            "model": "gpt-3.5-turbo-1106",
          };
          console.log(file_ids)
          if (file_ids.length>0){
            data["file_ids"] = file_ids
          }

          console.log(data)
      
          const response = await axios.post(endpoint, data, { headers });
          

          console.log('Assistant created:', response.data);
          return response.data.id
        } catch (error) {
          console.error('Error creating assistant:', error.response ? error.response.data : error.message);
          throw error;
        }
      };

      const deleteAssistant = async (assistantId) => {
        try {
          const endpoint = `https://api.openai.com/v1/assistants/${assistantId}`;
      
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
            'OpenAI-Beta': 'assistants=v1'
          };
          const response = await axios.delete(endpoint, { headers });
          console.log('Assistant deleted:', response.data);
          return response.data
        } catch (error) {
          console.error('Error deleting assistant:', error.response ? error.response.data : error.message);
          throw error;
        }
      };


      const createThread = async () => {
        try {
          const response = await axios.get(`${apiUrl}/new-thread`, {
            headers: {
              'openai_key': apiKey
            }
          });
      
          console.log('Thread created:', response.data);
          return response.data.threadId;
        } catch (error) {
          console.error('Error creating thread:', error.response ? error.response.data : error.message);
          throw error;
        }
      };

      async function assistantChat(assistantId,threadId, question ) {
        try {
            const response = await axios.post(
                `${apiUrl}/assistant-chat`,
                {
                    threadId: threadId,
                    question: question,
                    assistantId: assistantId
                },
                {
                    headers: {
                        'openai_key': apiKey,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    }
                }
            );
    
            console.log('Assistant response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            throw error;
        }
    }

    return {createAssistant,deleteAssistant,createThread,assistantChat,createAssistantWithoutUpload}
}

export default useAssistant