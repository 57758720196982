import React, { useState, useEffect } from "react";
import ActivePlanPopup from "./stripe/ActivePlanPopup";

function UserActivePackage({ isUserPremium }) {
    const [planData, setPlanData] = useState(null);
  
    useEffect(() => {
      const fetchPlanData = async () => {
        const data = await isUserPremium();
        console.log("isUserPremium  === ", data);
        if (data) {
          console.log("price plan done", data);
          setPlanData(data);
        }
      };
      fetchPlanData();
    }, []);
  
    if (!planData) return <div style={{ width: "100%", height: "80px" }}
      className=" linear-background">      </div>;
  
    return planData?.map((planData) => (
      <>
        <ActivePlanPopup
          price={planData?.planDetail?.price}
          planDetails={`${planData?.planDetail?.visitors} - ${planData?.planDetail?.interval}`}
          nextPaymentDue="Next scheduled payment date..."
        />
      </>
    ));
  }

export default UserActivePackage;