import { firebase  } from "../../../../config/firebase";
import initializeStripe from "./initializeStrips";

export async function createCheckoutSession(uid, priceId) {
  console.log('Creating checkout session', { uid, priceId });
  const firestore = firebase.firestore();

  try {
    const checkoutSessionRef = await firestore
      .collection("Users")
      .doc(uid?.uid)
      .collection("checkout_sessions")
      .add({
        mode: "subscription",
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

    console.log('checkout session created');

    checkoutSessionRef.onSnapshot(async (snap) => {
      const { sessionId, url, error } = snap.data();
      console.log('Snapshot data:', snap.data());

      if (error) {
        console.error('Error from snapshot:', error);
        return;
      }

      if (sessionId && url) {
        console.log('Redirecting to Stripe checkout:', url);
        window.location.href = url;
      } else {
        console.log('Session ID or URL not available yet');
      }
    });

    console.log('Listening for checkout session update...');
  } catch (error) {
    console.error("Error creating checkout session: ", error);
  }
}
