import React, { useEffect, useState } from 'react';
import useAuth from "../hooks/useAuth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../components";
import { getTotalUserCredit } from '../utils/AccountCredit';

const UserProfile = () => {
  const [isOpen, setIsOpen] = useState(false); 
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { user, isLoading } = useSelector(
    (state) => state.user.value
  );

  const [activeplanCredit, setActiveplanCredit] = useState(0);

  const updateCredits = () => {
    // Set a constant value for testing
    const testCreditValue = 100; // Change this value for testing
    setActiveplanCredit(testCreditValue);
  };

  useEffect(() => {
    const fetchAndUpdateCredits = async () => {
      await getTotalUserCredit(); // Ensure credits are fetched and stored in localStorage
      updateCredits(); // Update the component state with the fetched credits
    };

    fetchAndUpdateCredits();

    const handleStorageChange = (event) => {
      if (event.key === "availableCredit") {
        updateCredits();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
    navigate("/login");
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      {isLoading && <Spinner loading={isLoading} />}
      {user && !isLoading && (
        <div className="relative">
          <button onClick={toggleDropdown} className="flex items-center space-x-2 rounded-full py-2 px-4 cursor-pointer">
            <img className="h-8 w-8 rounded-full border border-gray-600" src={user.photoURL || "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"} alt="User profile" />
            <span>{user.displayName}</span>
            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-2 rounded border border-blue-400">
              {activeplanCredit} credits
            </span>
            <svg className="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
          {isOpen && (
            <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
              <a href="##" onClick={handleLogout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                Logout
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UserProfile;
