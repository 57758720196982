import { useDispatch } from "react-redux";
import { clearUser, setUser } from "../features/userSlice";
import { signInWithPopup, signOut } from "firebase/auth";
import { auth, provider, db } from "../config/firebase";
import {
  getDoc,
  setDoc,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import { addMonthlyCreditForUser, getUserPaymentDetails, saveUserPaymentDetails } from "../utils/AccountCredit";

const useAuth = () => {
  const dispatch = useDispatch();

  const usersCollectionRef = collection(db, "Users");

  const handleSaveUserData = async (user) => {
    try {
      const userDocRef = doc(usersCollectionRef, user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = {
          displayName: userDocSnapshot.data().displayName,
          email: userDocSnapshot.data().email,
          photoURL: userDocSnapshot.data().photoURL,
          uid: userDocSnapshot.data().uid,
          files:userDocSnapshot.data().files || [],
        };
        await updateDoc(userDocRef, userData);
        let userActivePlan=await getUserPaymentDetails(userDocSnapshot.data().uid);
                if (userActivePlan!==null) {
          localStorage.setItem("activePlan", JSON.stringify(userActivePlan));
        }

        return userData
      } else {
        const userData = {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uid: user.uid,
          files:[]
        };
        await setDoc(userDocRef, userData);
        console.log("User data saved successfully");

        const saved = await saveUserPaymentDetails(user.uid, 30, 'Plan A');
        await addMonthlyCreditForUser(user.uid);
        console.log('Payment Details Saved:', saved);


        return userData
      }
    } catch (error) {
      console.error("Error saving user data:", error);
      throw error;
    }
  };

  const signInWithGoogle = async () => {
    try {
      const results = await signInWithPopup(auth, provider);
      console.log(results);
      const userData = await handleSaveUserData(results.user);
      localStorage.setItem("userData", JSON.stringify(userData));
      dispatch(setUser(userData));
   
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUserInfoFromDb = async (uid) => {
    try {
      const userDocRef = doc(usersCollectionRef, uid);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        return userData
      } else {
        console.error("User document does not exist");
      }
    } catch (error) {
      console.error("Error updating array field:", error);
      throw error;
    }
  };

  const fetchUser = async () => {
    let userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      return dispatch(clearUser());
    }


    console.log('befire ',userData);
    await addMonthlyCreditForUser(userData.uid);

    dispatch(setUser(userData));
  };

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("userData");
      return dispatch(clearUser());
    } catch (err) {
      console.error(err);
    }
  };

  return { signInWithGoogle, logout, fetchUser,fetchUserInfoFromDb };
};

export default useAuth;
