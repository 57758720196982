import React from 'react'
//import { FeatureItemIcon } from '../icons';

function FeatureItem({ text }) {
    return (
      <li className="text-md flex items-start gap-2 leading-[32px]">
        <figure className="min-w-6 flex min-h-[32px] items-center text-green-600">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" stroke="currentColor" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="svg-icon w-5 mr-3"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
        </figure>
        <span>{text}</span>
      </li>
    );
  }
  
  export default FeatureItem;