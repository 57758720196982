
import {firebase}  from "../../../../config/firebase";
import { saveUserPaymentDetails } from "../../../../utils/AccountCredit";

const planDetails = [
  {
    id: 'price_1PC0dQSJyw6uAyO3WDCUxdot',
    interval: 'Monthly',
    visitors: '199 Magic Minutes / Month',
    price: '$1/month',
    limit: '199',
    website:"100"
  },
  {
    id: 'price_1PBkhdSJyw6uAyO3kLHB3xwi',
    interval: 'Monthly',
    visitors: '100 Magic Minutes / Month',
    price: '$37/year',
    limit: '100',
    website:"100"
  },
  {
    id: 'price_1PBkg2SJyw6uAyO3TrgZv8Vd',
    interval: 'Monthly',
    visitors: '300 Magic Minutes / Month',
    price: '$56/month',
    limit: '300',
    website:"100"
  },
  {
    id: 'price_1PBkiLSJyw6uAyO3An95Qpf6',
    interval: 'Monthly',
    visitors: '600 Magic Minutes / Month',
    price: '$83/month',
    limit: '600',
    website:"100"
  },
  {
    id: 'price_1PBkicSJyw6uAyO3QRpZ8rbJ',
    interval: 'Yearly',
    visitors: '1200 Magic Minutes / Year',
    price: '$444/year',
    limit: '1200',
    website:"100"
  },
  {
    id: 'price_1PC587SJyw6uAyO3SoWVrRlA',
    interval: 'Lifetime 1st Tire',
    visitors: '300 Magic Minutes / Month',
    price: '$73/year',
    limit: '300',
    website:"100"
  },
  {
    id: 'price_1PC5DaSJyw6uAyO3SC1q25eK',
    interval: 'Lifetime 2nd Tire',
    visitors: '500 Magic Minutes / Month',
    price: '$169/year',
    limit: '500',
    website:"100"
  }, 
  {
    id: 'price_1PC587SJyw6uAyO3SoWVrRlA',
    interval: 'Lifetime 3rd Tire',
    visitors: '800 Magic Minutes / Month',
    price: '$199/year',
    limit: '800',
    website:"100"
  }       
];


export async function getUserActivePlan() {
  try {
    const userId = JSON.parse(localStorage.getItem("userData")).uid;
    const firestore = firebase.firestore();

    const subscriptionSnapshot = await firestore.collection(`Users/${userId}/subscriptions`)
      .where('status', 'in', ['active', 'trialing'])
      .get();

    if (!subscriptionSnapshot.empty) {
      const subscriptionData = subscriptionSnapshot.docs[0].data();

      console.log('Subscription data:', subscriptionData);
      const activePlanDetail = planDetails.find(plan => plan.id === subscriptionData.price.id);

      saveUserPaymentDetails(
        userId,
        activePlanDetail?.limit,
        activePlanDetail?.id
      );

      
      return activePlanDetail ? activePlanDetail : null;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching active subscription:', error);
    throw error;
  }
}



export async function isUserPremium() {
  try {
    const userId = JSON.parse(localStorage.getItem("userData")).uid;
    const firestore = firebase.firestore();

    const paymentsSnapshot = await firestore.collection(`Users/${userId}/payments`).where("status", "==", "succeeded").get();

    let activePayments = [];

    paymentsSnapshot.forEach(doc => {
      const paymentData = doc.data();
      if (isActivePayment(paymentData)) {
        const planDetail = planDetails.find(plan => plan.id === paymentData.price_id); // Assuming 'price_id' correctly points to the plan ID
        activePayments.push(planDetail);
      }
    });

    // Default plan if no active payments are found
    if (activePayments.length === 0) {
      activePayments.push({
        id: "price_1",
        interval: "Unlimited Time",
        visitors: "30 Magic Minutes",
        price: "Free",
        limit: '30',
        website: "1"
      });
    }

    return activePayments;
  } catch (error) {
    console.error('Error fetching active payments:', error);
    throw error;
  }
}

function isActivePayment(paymentData) {
  const currentTime = Math.floor(Date.now() / 30);

  // Accessing nested properties
  const startDate = paymentData.payment_method_options.card.mandate_options.start_date;
  const interval = paymentData.payment_method_options.card.mandate_options.interval;

  // Check active period based on interval
  if (interval === 'month') {
    return currentTime < startDate + 30 * 24 * 60 * 60; // 30 days in seconds
  } else if (interval === 'year') {
    return currentTime < startDate + 365 * 24 * 60 * 60; // 365 days in seconds
  }

  return false;
}
