import React, { useState, useEffect } from "react";
import { createCheckoutSession } from "./pricing/stripe/createCheckoutSession";
import { isUserPremium } from "./pricing/stripe/isUserPremium";
import UserActivePackage from "./pricing/UserActivePackage";
import MonthlyYearly from "./pricing/MonthlyYearly";

function BillingModal({ isOpen, handleCloseBillingModal }) {
  const [activeTab, setActiveTab] = useState("Monthly");
  const [userData, setUserData] = useState(null);
  const [numberOfUsers, setNumberOfUsers] = useState(100);
  const [price, setPrice] = useState(12);
  const [interval, setInterval] = useState("Monthly");

  const calculatePrice = (numOfUsers) => {
    console.log('🟢 calculatePrice initiated for number of users:', numOfUsers);
    const monthlyPrices = {
      199: 1,
      100: 37,
      300: 56,
      600: 83,
      1200: 444,
      301: 73,
      501: 169,
      801: 199      
    };
    const calculatedPrice = monthlyPrices[numOfUsers] || 7;
    console.log('🟢 Calculated price:', calculatedPrice);
    return calculatedPrice;
  };

  useEffect(() => {
    console.log('🟢 useEffect for numberOfUsers triggered with value:', numberOfUsers);
    setPrice(calculatePrice(numberOfUsers));
  }, [numberOfUsers]);

  useEffect(() => {
    console.log('🟢 useEffect for fetching userData from localStorage');
    const userDataFromStorage = localStorage.getItem("userData");
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
      console.log('🟢 UserData fetched from localStorage:', parsedUserData);
    }
  }, []);

  const handleUserChange = (event) => {
    const newUserCount = parseInt(event.target.value);
    console.log('🟢 handleUserChange initiated with value:', newUserCount);
    setNumberOfUsers(newUserCount);
  };

  const handleSubscriptionChange = (tab) => {
    console.log('🟢 handleSubscriptionChange initiated with tab:', tab);
    setActiveTab(tab);
    setInterval(tab);
  };

  const handlePayment = async () => {
    console.log('🟢 handlePayment initiated');
    const priceId = getPriceId();
    if (userData && priceId) {
      await createCheckoutSession(userData, priceId);
      console.log('🟢 createCheckoutSession called with userData and priceId:', userData, priceId);
    } else {
      console.error("🔴 Missing userData or priceId");
    }
  };

  const getPriceId = () => {
    console.log('🟢 getPriceId initiated');
    const priceMap = {
      Monthly: {
        199: "price_1PC0dQSJyw6uAyO3WDCUxdot",
        100: "price_1PBkhdSJyw6uAyO3kLHB3xwi",
        300: "price_1PBkg2SJyw6uAyO3TrgZv8Vd",
        600: "price_1PBkiLSJyw6uAyO3An95Qpf6",
        1200: "price_1PBkicSJyw6uAyO3QRpZ8rbJ",
        301: "price_1PC587SJyw6uAyO3SoWVrRlA",
        501: "price_1PC5DaSJyw6uAyO3SC1q25eK",
        801: "price_1PC5ELSJyw6uAyO3R5PUtTCW"
      },
    };
    const selectedPriceId = priceMap["Monthly"][numberOfUsers];
    console.log('🟢 Selected priceId:', selectedPriceId);
    return selectedPriceId;
  };

  if (!isOpen) return null;

  console.log('🟢 BillingModal is open');

  return (
    <div className="fixed inset-0 z-10 overflow-x-hidden overflow-y-auto">
      <div className="fixed inset-0 bg-black opacity-50" onClick={handleCloseBillingModal}></div>
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white rounded-lg shadow-lg relative overflow-auto" style={{ maxWidth: "42rem", maxHeight: "90vh" }}>
          <button
            className="absolute top-0 right-0 m-4 p-2 text-lg font-bold"
            onClick={handleCloseBillingModal}
          >
            &times;
          </button>
          <section className="flex flex-col items-center p-6">
            <UserActivePackage isUserPremium={isUserPremium} />
            <div className="mb-4 w-full">
              <label htmlFor="num-of-views" className="block text-sm font-medium text-gray-700">
                Number of Meeting Credits / every month
              </label>
              <select
                onChange={handleUserChange}
                className="mt-1 block w-full py-2 px-3 border-2 border-gray-500 rounded-md text-2xl"
                id="num-of-views"
              >
                <option value="199">Test 199 Monthly</option>
                <option value="100">100 / Month</option>
                <option value="300">300 / Month</option>
                <option value="600">600 / Month</option>
                <option value="1200">1200 / Quarterly</option>
                <option value="301">301 / Month</option>
                <option value="501">501 / Month</option>
                <option value="801">801/ Month</option>
              </select>
            </div>
            <article className="overflow-hidden rounded-lg border-2 bg-gray-50 pb-5 w-full">
              <MonthlyYearly
                price={price}
                handlePayment={handlePayment}
                featureTexts={["AI Models - Advanced", "Interview Copilot®️", "Coding Copilot", "Unlimited Mock Interview", "Unlimited Resume Revision", "24/7 Live Support"]}
                time={interval}
              />
            </article>
          </section>
        </div>
      </div>
    </div>
  );
}

export default BillingModal;
