import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { GoogleSignInButton, Spinner } from "../components";

const LoginPage = () => {
  const { signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    setLoading(true);
    try {
      await signInWithGoogle();
      navigate("/");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  

  return (
    <>
      {loading ? (
        <Spinner loading={loading} />
      ) : (
        <div>
          <div className="h-screen bg-gray-100 pt-12">
            <div className=" flex flex-col justify-center p-8 sm:px-4 lg:px-4 m-auto text-center border border-gray-300 shadow-md rounded-md bg-white w-1/2">
              <div>
                <img
                  className="w-auto  m-auto mb-4"
                  src="/logo.svg"
                  alt="Workflow"
                  style={{
                    width: "15rem",
                  }}
                />
                <div className="sm:w-full sm:max-w-xl px-0 m-auto">
                  <h6 className="mt-6 text-2xl">
                    Go on <b className="font-bold">Calls with AI</b> by your
                    side.
                  </h6>
                  <p className="mt-1 text-base font-normal text-gray-500 px-8 pt-2">
                    AI Copilot®️ generating actionable guidance in real-time for
                    Interview, Hiring, Team Meetings and Sales
                    Calls.
                  </p>
                </div>
                <div className="py-2 px-4">
                  <div className="sm:w-full sm:max-w-lg px-0 m-auto"></div>
                  <form className="mt-8 space-y-6 w-3/4 m-auto">
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                      <div className="mb-2">
                        <label htmlFor="email-address" className="sr-only">
                          Email address
                        </label>
                        <input
                          name="email"
                          type="email"
                          autoComplete="email"
                          required=""
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-dark-500 focus:border-dark-500 focus:z-10 sm:text-sm"
                          placeholder="Email address"
                          defaultValue="hey@vaibhavkalra.com"
                        />
                      </div>
                      <div>
                        <label htmlFor="password" className="sr-only">
                          Password
                        </label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required=""
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-dark-500 focus:border-dark-500 focus:z-10 sm:text-sm"
                          placeholder="Password"
                          defaultValue="demodemo"
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 text-dark-600 focus:ring-dark-500 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="remember-me"
                          className="ml-2 block text-sm text-gray-900"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="text-sm">
                        <a className="font-medium text-dark-600 hover:text-dark-500">
                          Forgot your password?
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <div className="w-full flex justify-center">
                          <GoogleSignInButton onClick={handleSignIn} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPage;
