import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from '@firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCbBR2aIwfR-iPHZC6zFLcjS7EdZs5KeNA",
  authDomain: "ai-copilot-104.firebaseapp.com",
  projectId: "ai-copilot-104",
  storageBucket: "ai-copilot-104.appspot.com",
  messagingSenderId: "836821389098",
  appId: "1:836821389098:web:3cfb286b3fcb0e728c5853",
  measurementId: "G-0KZKJCEF1N"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);
export {firebase};
