import { useState, useEffect, useRef } from 'react';

function Dropdown({ optionValues, updateValues }) {
    const [options, setOptions] = useState(optionValues);
    const [selected, setSelected] = useState([]);
    const [show, setShow] = useState(false);
    const dropdownRef = useRef(null); // Reference to the dropdown

    const open = () => setShow(!show);

    const select = (value) => {
        const updatedOptions = options.map(option => {
            if (option.value === value) {
                return { ...option, selected: !option.selected };
            }
            return option;
        });
        const updatedSelected = updatedOptions.filter(option => option.selected).map(option => option.value);
        setSelected(updatedSelected);
        setOptions(updatedOptions);
    }

    const remove = (value) => {
        const updatedOptions = options.map(option => {
            if (option.value === value) {
                return { ...option, selected: false };
            }
            return option;
        });
        const updatedSelected = selected.filter(option => option !== value);
        setSelected(updatedSelected);
        setOptions(updatedOptions);
    };
    const handleUpdateValues = ()=>{
        updateValues(selected);
    }

    useEffect(() => {
        console.log('selected', selected);
        handleUpdateValues()
    }, [selected]);

    // Handle outside clicks
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShow(false);
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div ref={dropdownRef}>
            <div>
                <div className="inline-block relative w-full">
                    <div className="flex flex-col items-center relative">
                        <div onClick={open} className="w-full">
                            <div className=" p-1 flex border border-gray-200 bg-white rounded mt-1 w-full rounded-md py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-design-orange">
                                <div className="flex flex-auto flex-wrap">
                                    {selected.map((optionValue, index) => (
                                        <div key={optionValue} className="flex justify-center items-center m-1 text-bold py-1 px-4 bg-blue-400 rounded-full text-blue-700 bg-blue-100 border border-blue-300 space-x-2">
                                            <div className="text-xs font-bold leading-none max-w-full flex-initial">
                                                {options.find(option => option.value === optionValue).text}
                                            </div>
                                            <div className="flex flex-auto flex-row-reverse text-blue-500 opacity-50">
                                                <div onClick={() => remove(optionValue)}>
                                                    <svg className="fill-current h-6 w-6 " role="button" viewBox="0 0 20 20">
                                                        <path d="M14.348,14.849c-0.469,0.469-1.229,0.469-1.697,0L10,11.819l-2.651,3.029c-0.469,0.469-1.229,0.469-1.697,0
                                                        c-0.469-0.469-0.469-1.229,0-1.697l2.758-3.15L5.651,6.849c-0.469-0.469-0.469-1.228,0-1.697s1.228-0.469,1.697,0L10,8.183
                                                        l2.651-3.031c0.469-0.469,1.228-0.469,1.697,0s0.469,1.229,0,1.697l-2.758,3.152l2.758,3.15
                                                        C14.817,13.62,14.817,14.38,14.348,14.849z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {selected.length === 0 &&
                                        <div className="flex-1">
                                            <input placeholder="Select multiple options" className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800" />
                                        </div>
                                    }
                                </div>
                                <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200">
                                    <button type="button" style={{ display: show === true ? 'inline' : 'none' }} onClick={open} className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                                        <svg version="1.1" className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                            <path d="M17.418,6.109c0.272-0.268,0.709-0.268,0.979,0s0.271,0.701,0,0.969l-7.908,7.83
                                                        c-0.27,0.268-0.707,0.268-0.979,0l-7.908-7.83c-0.27-0.268-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.979,0L10,13.25
                                                        L17.418,6.109z" />
                                        </svg>
                                    </button>
                                    <button type="button" style={{ display: show === false ? 'inline' : 'none' }} onClick={open} className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                                        <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                                            <path d="M2.582,13.891c-0.272,0.268-0.709,0.268-0.979,0s-0.271-0.701,0-0.969l7.908-7.83
                                                        c0.27-0.268,0.707-0.268,0.979,0l7.908,7.83c0.27,0.268,0.27,0.701,0,0.969c-0.271,0.268-0.709,0.268-0.978,0L10,6.75
                                                        L2.582,13.891z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="w-full px-4">
                            <div className="absolute shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto">
                                {show &&
                                    <div className="flex flex-col w-full">
                                        {options.map((option, index) => (
                                            <div key={option.value} className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100" onClick={() => select(option.value)}>
                                                <div className={option.selected ? 'border-teal-600 flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative' : 'flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative'}>
                                                    <div className="w-full items-center flex">
                                                        <div className="mx-2 leading-6">{option.text}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dropdown;
