import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { meetingTypes } from "../../constants";
import Dropdown from "../Dropdown";

function StartInterviewModal({ isOpen, onClose, onSaveStartInterview }) {
  const [details, setDetails] = useState({
    goal: "",
    codingLang: [],
    knowledgeDocs: [],
    additionalDetails: "",
    selectedMeetingType: "Interview",
  });
  const { user, isLoading } = useSelector((state) => state.user.value);

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value, options } = e.target;

    if (options) {
      // Debugging: Log current selections
      console.log(`Before update for ${name}:`, details[name]);

      const values = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
      setDetails({ ...details, [name]: values });

      // Debugging: Log the values to be set
      console.log(`After update for ${name}:`, values);
    } else {
      setDetails({ ...details, [name]: value });
    }
  };

  const handleMeetingTypeChange = (round) => {
    setDetails({ ...details, selectedMeetingType: round });
  };

  

  const handleSubmit = () => {
    if (details.goal === "") {
      toast.error("Please fill the form properly");
      return;
    }
    onSaveStartInterview(details);
  };

  const updateKnowledgeDocs = (newDocs) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      knowledgeDocs: newDocs,
    }));
  };
  const updateCodingLang = (langs) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      codingLang: langs,
    }));
  };

  const knowledgeOptions =
    user &&
    !isLoading &&
    user.files.map((file) => ({
      value: file.fileId,
      text: file.filename,
      selected: false,
    }));

    const codingOptions = [
      {
        value: "javascript",
        text: "JavaScript",
        selected: false,
      },
      {
        value: "python",
        text: "Python",
        selected: false,
      },
      {
        value: "java",
        text: "Java",
        selected: false,
      },
      {
        value: "csharp",
        text: "C#",
        selected: false,
      },
      {
        value: "cpp",
        text: "C++",
        selected: false,
      },
      {
        value: "ruby",
        text: "Ruby",
        selected: false,
      },
      {
        value: "kotlin",
        text: "Kotlin",
        selected: false,
      },
      {
        value: "perl",
        text: "Perl",
        selected: false,
      },
      {
        value: "php",
        text: "PHP",
        selected: false,
      },
      {
        value: "dart",
        text: "Dart",
        selected: false,
      },
      {
        value: "tcl",
        text: "Tcl",
        selected: false,
      },
      {
        value: "r",
        text: "R",
        selected: false,
      },
      {
        value: "julia",
        text: "Julia",
        selected: false,
      },
      {
        value: "matlab",
        text: "MATLAB",
        selected: false,
      },
      {
        value: "objective-c",
        text: "Objective-C",
        selected: false,
      },
      {
        value: "scala",
        text: "Scala",
        selected: false,
      },
      {
        value: "groovy",
        text: "Groovy",
        selected: false,
      },
      {
        value: "fsharp",
        text: "F#",
        selected: false,
      },
      {
        value: "typescript",
        text: "TypeScript",
        selected: false,
      },
      {
        value: "coffeescript",
        text: "CoffeeScript",
        selected: false,
      },
      {
        value: "elixir",
        text: "Elixir",
        selected: false,
      },
      {
        value: "clojure",
        text: "Clojure",
        selected: false,
      },
      {
        value: "haskell",
        text: "Haskell",
        selected: false,
      },
      {
        value: "erlang",
        text: "Erlang",
        selected: false,
      },
      {
        value: "crystal",
        text: "Crystal",
        selected: false,
      },
      {
        value: "nim",
        text: "Nim",
        selected: false,
      },
      {
        value: "cobol",
        text: "Cobol",
        selected: false,
      },
      {
        value: "fortran",
        text: "Fortran",
        selected: false,
      },
      {
        value: "html",
        text: "HTML",
        selected: false,
      },
      {
        value: "css",
        text: "CSS",
        selected: false,
      },
      {
        value: "xml",
        text: "XML",
        selected: false,
      },
      {
        value: "json",
        text: "JSON",
        selected: false,
      },
      {
        value: "yaml",
        text: "YAML",
        selected: false,
      },
      {
        value: "jsx",
        text: "JSX",
        selected: false,
      },
      {
        value: "sass",
        text: "Sass",
        selected: false,
      },
      {
        value: "less",
        text: "LESS",
        selected: false,
      },
      {
        value: "webassembly",
        text: "WebAssembly",
        selected: false,
      },
      {
        value: "elm",
        text: "Elm",
        selected: false,
      },
      {
        value: "purescript",
        text: "PureScript",
        selected: false,
      },
      {
        value: "reasonml",
        text: "ReasonML",
        selected: false,
      },
      {
        value: "haml",
        text: "Haml",
        selected: false,
      },
      {
        value: "nodejs",
        text: "Node.js",
        selected: false,
      },
      {
        value: "django",
        text: "Django",
        selected: false,
      },
      {
        value: "flask",
        text: "Flask",
        selected: false,
      },
      {
        value: "rubyonrails",
        text: "Ruby on Rails",
        selected: false,
      },
      {
        value: "spring",
        text: "Spring",
        selected: false,
      },
      {
        value: "laravel",
        text: "Laravel",
        selected: false,
      },
      {
        value: "expressjs",
        text: "Express.js",
        selected: false,
      },
      {
        value: "aspnet",
        text: "ASP.NET",
        selected: false,
      },
      {
        value: "gin",
        text: "Gin",
        selected: false,
      },
      {
        value: "rocket",
        text: "Rocket",
        selected: false,
      },
      {
        value: "sql",
        text: "SQL",
        selected: false,
      },
      {
        value: "plsql",
        text: "PL/SQL",
        selected: false,
      },
      {
        value: "tsql",
        text: "T-SQL",
        selected: false,
      },
      {
        value: "android",
        text: "Android",
        selected: false,
      },
      {
        value: "ios",
        text: "iOS",
        selected: false,
      },
      {
        value: "flutter",
        text: "Flutter",
        selected: false,
      },
      {
        value: "reactnative",
        text: "React Native",
        selected: false,
      },
      {
        value: "xamarin",
        text: "Xamarin",
        selected: false,
      },
      {
        value: "ionic",
        text: "Ionic",
        selected: false,
      },
      {
        value: "objectivec_ios",
        text: "Objective-C (iOS)",
        selected: false,
      },
      {
        value: "unity",
        text: "Unity",
        selected: false,
      },
      {
        value: "unrealengine",
        text: "Unreal Engine",
        selected: false,
      },
      {
        value: "godotengine",
        text: "Godot Engine",
        selected: false,
      },
      {
        value: "cryengine",
        text: "CryEngine",
        selected: false,
      },
      {
        value: "bash",
        text: "Bash",
        selected: false,
      },
      {
        value: "powershell",
        text: "PowerShell",
        selected: false,
      },
      {
        value: "awk",
        text: "AWK",
        selected: false,
      },
      {
        value: "sed",
        text: "sed",
        selected: false,
      },
      {
        value: "expect",
        text: "Expect",
        selected: false,
      },
      {
        value: "lua_scripting",
        text: "Lua (Scripting)",
        selected: false,
      },
      {
        value: "xml_markup",
        text: "XML (Markup)",
        selected: false,
      },
      {
        value: "html_markup",
        text: "HTML (Markup)",
        selected: false,
      },
      {
        value: "markdown_markup",
        text: "Markdown (Markup)",
        selected: false,
      },
      {
        value: "latex_markup",
        text: "LaTex (Markup)",
        selected: false,
      },
      {
        value: "yaml_markup",
        text: "YAML (Markup)",
        selected: false,
      },
      {
        value: "json_markup",
        text: "JSON (Markup)",
        selected: false,
      },
      {
        value: "git",
        text: "Git",
        selected: false,
      },
      {
        value: "svn",
        text: "SVN",
        selected: false,
      },
      {
        value: "mercurial",
        text: "Mercurial",
        selected: false,
      },
      {
        value: "adobexd",
        text: "Adobe XD",
        selected: false,
      },
      {
        value: "sketch",
        text: "Sketch",
        selected: false,
      },
      {
        value: "figma",
        text: "Figma",
        selected: false,
      },
      {
        value: "photoshop",
        text: "Adobe Photoshop",
        selected: false,
      },
      {
        value: "illustrator",
        text: "Adobe Illustrator",
        selected: false,
      },
      {
        value: "invision",
        text: "InVision",
        selected: false,
      },
      {
        value: "axurerp",
        text: "Axure RP",
        selected: false,
      },
      {
        value: "zeplin",
        text: "Zeplin",
        selected: false,
      },
      {
        value: "embeddedc",
        text: "Embedded C",
        selected: false,
      },
      {
        value: "assembly",
        text: "Assembly Language",
        selected: false,
      },
      {
        value: "tensorflow",
        text: "TensorFlow",
        selected: false,
      },
      {
        value: "pytorch",
        text: "PyTorch",
        selected: false,
      },
      {
        value: "scikitlearn",
        text: "scikit-learn",
        selected: false,
      },
      {
        value: "git",
        text: "Git",
        selected: false,
      },
      {
        value: "svn",
        text: "SVN",
        selected: false,
      },
      {
        value: "mercurial",
        text: "Mercurial",
        selected: false,
      },
      {
        value: "android",
        text: "Android",
        selected: false,
      },
      {
        value: "ios",
        text: "iOS",
        selected: false,
      },
      {
        value: "flutter",
        text: "Flutter",
        selected: false,
      },
      {
        value: "reactnative",
        text: "React Native",
        selected: false,
      },
      {
        value: "xamarin",
        text: "Xamarin",
        selected: false,
      },
      {
        value: "ionic",
        text: "Ionic",
        selected: false,
      },
      {
        value: "objectivec_ios",
        text: "Objective-C (iOS)",
        selected: false,
      },
      {
        value: "unity",
        text: "Unity",
        selected: false,
      },
      {
        value: "unrealengine",
        text: "Unreal Engine",
        selected: false,
      },
      {
        value: "godotengine",
        text: "Godot Engine",
        selected: false,
      },
      {
        value: "cryengine",
        text: "CryEngine",
        selected: false,
      },
      {
        value: "c",
        text: "C",
        selected: false,
      },
      {
        value: "powershell",
        text: "PowerShell",
        selected: false,
      },
      {
        value: "go",
        text: "Go",
        selected: false,
      },
      {
        value: "sql",
        text: "SQL",
        selected: false,
      },
      {
        value: "typescript",
        text: "TypeScript",
        selected: false,
      },
      {
        value: "html",
        text: "HTML",
        selected: false,
      },
      {
        value: "css",
        text: "CSS",
        selected: false,
      },
      {
        value: "xml",
        text: "XML",
        selected: false,
      },
      {
        value: "json",
        text: "JSON",
        selected: false,
      },
      {
        value: "yaml",
        text: "YAML",
        selected: false,
      },
      {
        value: "jsx",
        text: "JSX",
        selected: false,
      },
      {
        value: "sass",
        text: "Sass",
        selected: false,
      },
      {
        value: "less",
        text: "LESS",
        selected: false,
      },
      {
        value: "webassembly",
        text: "WebAssembly",
        selected: false,
      },
      {
        value: "embeddedc",
        text: "Embedded C",
        selected: false,
      },
      {
        value: "assembly",
        text: "Assembly Language",
        selected: false,
      },
      {
        value: "bash",
        text: "Bash",
        selected: false,
      },
      {
        value: "lua",
        text: "Lua",
        selected: false,
      },
      {
        value: "swift",
        text: "Swift",
        selected: false,
      },
      {
        value: "rust",
        text: "Rust",
        selected: false,
      },
    ];
    
    

  console.log("Current State:", details);

  console.log("meetingTypes ", meetingTypes);

  console.log("files", user.files);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-center z-10">
      <div className="relative transform rounded-lg bg-white shadow-xl transition-all p-6 sm:my-8 sm:max-w-lg text-left h-screen overflow-auto">
        <h2 className="text-xl font-semibold leading-6 text-gray-900 mb-4">
          AI Copilot
        </h2>
        <p className="mt-2 text-sm text-gray-500 mb-8">
          Please select the company you wish to apply to and provide your resume
          or an optional cover letter. Copilot will then deliver personalized
          support.
        </p>

        {/* Meeting Type Buttons */}
        <div className="mt-4">
          <div className="flex justify-between">
            <label
              htmlFor="codingLang"
              className="block text-sm font-medium text-gray-900 mb-2"
            >
              Meeting Type
            </label>
            <span className="block font-normal opacity-50 text-xs">
              Required
            </span>
          </div>

          <div className="isolate inline-flex rounded-md shadow-sm w-full">
            {meetingTypes &&
              meetingTypes.map((round) => (
                <button
                  key={round}
                  onClick={() => handleMeetingTypeChange(round)}
                  className={`first:rounded-l-md last:rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-gray-300 focus:z-10 w-full ${
                    details.selectedMeetingType === round
                      ? "bg-blue-600 text-white"
                      : ""
                  }`}
                >
                  {round}
                </button>
              ))}
          </div>
        </div>

        <div className="border p-4 mt-4 rounded bg-gray-100">
          {/* Goal Input Field */}
          <div className="mt-4">
            <div className="flex justify-between">
              <label
                htmlFor="goal"
                className="block text-sm font-medium text-gray-900 mb-2"
              >
                Meeting Goal
              </label>
              <span className="block font-normal opacity-50 text-xs">
                Required
              </span>
            </div>
            <textarea
              className="mt-1 w-full rounded-md bg-white py-1.5 pl-3 pr-10 text-sm text-gray-900 shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-design-orange"
              id="goal"
              name="goal"
              value={details.goal}
              onChange={handleChange}
              placeholder="Get a job as a React Developer"
            />
          </div>
          <div className="mt-4">

          <div className="flex justify-between">
              <label
                htmlFor="knowledgeattached"
                className="block text-sm font-medium text-gray-900 mb-2"
              >
                Select Knowledge
              </label>
              <span className="block font-normal opacity-50 text-xs">
                Required
              </span>
            </div>

            <Dropdown
              optionValues={knowledgeOptions}
              updateValues={updateKnowledgeDocs}
            />
          </div>

          <div className="mt-4">

          <div className="flex justify-between">
              <label
                htmlFor="codinglang"
                className="block text-sm font-medium text-gray-900 mb-2"
              >
                Coding Proficiency
              </label>
              <span className="block font-normal opacity-50 text-xs">
                Required
              </span>
            </div>

            <Dropdown
              optionValues={codingOptions}
              updateValues={updateCodingLang}
            />
          </div>

          {/* Additional Details */}
          <div className="mt-4">
            <div className="flex justify-between">
              <label
                htmlFor="additionalDetails"
                className="block text-sm font-medium text-gray-900 mb-2"
              >
                Additional Details
              </label>
              <span className="block font-normal opacity-50 text-xs">
                Optional
              </span>
            </div>
            <textarea
              className="mt-1 block w-full rounded-lg border border-gray-300 p-2.5 text-sm text-gray-900 focus:ring-design-orange-pressed focus:border-design-orange-pressed"
              id="additionalDetails"
              name="additionalDetails"
              rows="4"
              placeholder="Give some last minute details"
              value={details.additionalDetails}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2 mt-4">
          <button
            onClick={onClose}
            className="px-3 py-2 rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
          >
            Close
          </button>
          <button
            onClick={handleSubmit}
            className="px-3 py-2 rounded-md bg-blue-600 text-sm font-semibold text-white shadow-sm hover:bg-design-orange-pressed"
          >
            Start Meeting AI
          </button>
        </div>
      </div>
    </div>
  );
}

export default StartInterviewModal;
