import { json } from "react-router-dom";
import { db } from "../config/firebase";
import { doc, setDoc, collection, getDocs, query, where, updateDoc, getDoc } from "firebase/firestore"; 

const getUserPaymentDetails = async (userId) => {
    console.log('🟢 getUserPaymentDetails initiated for user:', userId);
    const paymentRef = collection(db, "payments");
    const q = query(paymentRef, where("userId", "==", userId));
    const paymentSnap = await getDocs(q);
    
    if (!paymentSnap.empty) {
        console.log('🟢 Payment details found for user:', userId);
        return paymentSnap.docs.map(doc => doc.data());
    } else {
        console.log("🟡 No payment document found for user:", userId);
        return null;
    }
}

const saveUserPaymentDetails = async (userId, credit, availablePlan) => {
    console.log('🟢 saveUserPaymentDetails initiated for user:', userId, { credit, availablePlan });
    const paymentRef = doc(db, "payments", userId);
    await updateDoc(paymentRef, {
        userId, 
        credit,
        availablePlan,
    });
    console.log('🟢 Payment details updated for user:', userId);
    return true;
}

const getTotalUserCredit = async () => {
    console.log('🟢 getTotalUserCredit initiated');
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (!userData || !userData.uid) {
        console.log("🟡 No user data found in localStorage");
        return 0;
    }

    const userId = userData.uid;
    const paymentDetails = await getUserPaymentDetails(userId);
    console.log('🟢 Retrieved payment details:', paymentDetails);

    if (paymentDetails && paymentDetails.length > 0) {
        const { credit = 0, Montlycredit = 0 } = paymentDetails[0];
        const totalCredit = parseInt(credit, 10) + Montlycredit;
        localStorage.setItem('availableCredit', totalCredit);
        console.log('🟢 Total user credit calculated:', totalCredit);
        return totalCredit;
    } else {
        console.log("🟡 No payment details found for user:", userId);
        return 50; // Default credits if no payment details found
    }
}

const addMonthlyCreditForUser = async (userId) => {
    console.log('🟢 addMonthlyCreditForUser initiated for user:', userId);
    const paymentRef = doc(db, "payments", userId);
    const paymentSnap = await getDoc(paymentRef);
    const now = new Date();

    if (paymentSnap.exists()) {
        const paymentData = paymentSnap.data();
        const lastCreditUpdate = paymentData.lastCreditUpdate?.toDate();
        const daysSinceLastUpdate = lastCreditUpdate ? (now - lastCreditUpdate) / (1000 * 60 * 60 * 24) : 31;

        if (daysSinceLastUpdate > 30) {
            if (paymentData.availablePlan) {
                const newCredit = 30;
                await updateDoc(paymentRef, { 
                    ...paymentData, 
                    Montlycredit: newCredit, 
                    lastCreditUpdate: now  
                });
                console.log('🟢 Monthly credit added for user:', userId);
                return true;
            } else {
                console.log('🟡 User does not have an available plan, monthly credit not added:', userId);
                return false;
            }
        } else {
            console.log('🟡 Monthly credit not added as the update was recent for user:', userId);
            return false;
        }
    } else {
        await setDoc(paymentRef, { 
            userId,
            credit: 50, // Default credits for new user
            Montlycredit: 0,
            availablePlan: null,
            lastCreditUpdate: now
        });
        console.log('🟢 Monthly credit initialized for new user:', userId);
        return true;
    }
}

const getRemainingCredit = async (userId) => {
    console.log('🟢 getRemainingCredit initiated for user:', userId);
    const paymentDetails = await getUserPaymentDetails(userId);
    
    if (paymentDetails && paymentDetails[0]) {
        const remainingCredit = paymentDetails[0]?.credit;
        console.log('🟢 Remaining credit for user:', userId, 'is', remainingCredit);
        return remainingCredit >= 0 ? remainingCredit : 0;
    } else {
        console.log("🟡 No payment details found for user:", userId);
        return 50; // Default credits if no payment details found
    }
}

const updateRemainingCredit = async (userId, remainingCredit) => {
    console.log('🟢 updateRemainingCredit initiated for user:', userId, { remainingCredit });
    const paymentRef = doc(db, "payments", userId);
    await updateDoc(paymentRef, { 'credit': remainingCredit });
    console.log('🟢 Remaining credit updated for user:', userId);
    return true;
}

const deductCredit = async (userId, passedTime) => {
    console.log('🟢 deductCredit initiated for user:', userId, 'with passed time:', passedTime);
    const remainingCreditBeforeDeduct = await getRemainingCredit(userId);
    const time = passedTime.split(':');
    const minutesPassed = (+time[0]) * 60 + (+time[1]);
    const remainingCredit = remainingCreditBeforeDeduct - minutesPassed;
    const updated = await updateRemainingCredit(userId, remainingCredit >= 0 ? remainingCredit : 0);
    const finalUserPaymentDetails = await getUserPaymentDetails(userId);
    localStorage.setItem("activePlan", JSON.stringify(finalUserPaymentDetails[0]));
    window.location.reload();
    console.log('🟢 Credit deducted for user:', userId);
    return updated;
}

const getUserDetails = () => {
    console.log('🟢 getUserDetails initiated');
    const user = JSON.parse(localStorage.getItem('userData')) || {};
    if (Object.keys(user).length === 0) {
        console.log("🟡 No user data found in localStorage");
        return null;
    }
    const userId = user.uid;
    console.log('🟢 User details retrieved:', userId);
    return { userId };
}

const testFunctions = async () => {
    console.log('🟢 testFunctions initiated');
    const user = JSON.parse(localStorage.getItem('userData')) || {};

    if (Object.keys(user).length === 0) {
        console.log("🟡 No user data found in localStorage");
        return;
    }
    
    const userId = user.uid;
    console.log(`🟢 Testing for user: ${userId}`);
    
    const userPaymentDetails = await getUserPaymentDetails(userId);
    console.log('🟢 User Payment Details:', userPaymentDetails);

    const saved = await saveUserPaymentDetails(userId, 100, 'Plan A');
    console.log('🟢 Payment Details Saved:', saved);

    const updatedUserPaymentDetails = await getUserPaymentDetails(userId);
    console.log('🟢 Updated User Payment Details:', updatedUserPaymentDetails);

    const remainingCredit = await getRemainingCredit(userId, '00:30:00');
    console.log('🟢 Remaining Credit:', remainingCredit);
    
    const deductCreditvar = await deductCredit(userId, '00:30:00');
    console.log('🟢 deductCredit Credit Updated:', deductCreditvar);

    const finalUserPaymentDetails = await getUserPaymentDetails(userId);
    console.log('🟢 Final User Payment Details:', finalUserPaymentDetails);
}

const isValidCredit = (credit, usedTime) => {
    console.log('🟢 isValidCredit initiated with credit:', credit, 'and used time:', usedTime);
    const time = usedTime.split(':');
    const minutesUsed = (+time[0]) * 60 + (+time[1]) + (+time[2] / 60);

    const isValid = minutesUsed <= credit;
    console.log('🟢 isValidCredit result:', isValid);
    return isValid;
}

export { getUserPaymentDetails, deductCredit, saveUserPaymentDetails, getRemainingCredit, updateRemainingCredit, addMonthlyCreditForUser, testFunctions, getUserDetails, getTotalUserCredit, isValidCredit };
