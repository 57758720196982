import React from 'react'
import PackageFeatureList from './PackageFeatureList'

function MonthlyYearly({ price,handlePayment,featureTexts ,interval}) {
  return (
    <div className="border-b-2 border-dashed px-7 py-6 md:pt-7">
    <h5 className="font-cal leading-[100%] md:!leading-h1 text-[40px] md:text-5xl lg:text-[50px] xl:text-6xl text-primary-700 flex items-end gap-2 md:min-h-[48px] md:items-center lg:min-h-[50px] xl:min-h-[60px]">
      <span>
        <div>${price}</div>
      </span>
      <span className=":top-2 relative text-3xl leading-8 text-neutral-500 lg:top-3">
        / {interval}
      </span>
    </h5>

    <div className="flex grow flex-col gap-2 md:flex-row md:items-end mt-6 mb-4">
      <button
        className="bg-blue-600 text-white w-full p-4 rounded-lg text-center"
        onClick={handlePayment}
      >
        Upgrade
      </button>
    </div>




    <p className="text-md max-w-[482px] pt-6 text-gray-500 md:pt-4">
      We work better in teams. Extend your workflows with
      round-robin and collective events and make advanced
      routing forms.
    </p>

    <PackageFeatureList features={featureTexts} />
  </div>
  )
}

export default MonthlyYearly