import React from 'react';

function ActivePlanPopup({ price, planDetails, nextPaymentDue }) {
  return (
    <div className="justify-center mb-4 my-5 w-full">

      <div className="text-center">
        <h6 className="text-3xl md:text-2xl font-extrabold leading-tighter tracking-tighter mb-4 aos-init aos-animate" data-aos="zoom-y-out">Go on <span className="bg-clip-text text-transparent text-blue-500">Calls with AI</span> by your side.</h6>
        <div className="max-w-3xl mx-auto">
          <p className="text-xl text-gray-600 mb-8 aos-init aos-animate" data-aos="zoom-y-out" data-aos-delay="150">
            <b className="text-gray-900">AI Copilot®️ </b> generating actionable guidance in real-time for <b className="text-gray-900">Interviewer, Interview Assesment, Team Meetings and Sales Calls</b>.
          </p>
        </div>
      </div>
      <div className="w-100">
        <div className="border rounded-md p-4 shadow-sm bg-white border-1 border-gray-500">
          <div className="flex justify-between text-sm font-medium">
            <h4 className="capitalize">Current Plan</h4>
            <h4 id="price-info">{price}</h4>
          </div>
          <div className="flex justify-between text-sm font-medium text-gray-500">
            <div>
              <p className="mb-0">
                <span id="current-plan-active">{planDetails}</span>
                &nbsp;
                <span className="badge bg-blue-700 text-white py-1 px-2 rounded">Active Plan</span>
              </p>
            </div>
            <p className="mb-0 hidden" id="last-date-info">{nextPaymentDue}</p>
          </div>
        </div>

        <h5 className="my-4 text-sm text-center">
          Click
          <a href="https://billing.stripe.com/p/login/8wMcNL4ureQIgGQbII" target="_blank" rel="noopener noreferrer" className="text-primary underline px-2 text-primary">here</a>
          to manage existing plan.
        </h5>

      </div>
    </div>
  );
}

export default ActivePlanPopup;
