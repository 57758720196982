import { useState } from "react";
import { useForm } from "react-hook-form";
import useAssistantFileUpload from "../../hooks/useAssistantFileUpload";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function KnowledgeModal({ isOpen, onClose, onSaveKnowledge }) {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState("resume");
  const { user, isLoading } = useSelector((state) => state.user.value);
  const [authError, setAuthError] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { handleFileUpload, handleUpdateFilesInDb, handleDeleteFileFromDb } = useAssistantFileUpload();

  if (!isOpen) return null;

  // Function to change the active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const onSubmit = async (data) => {
    console.log(data);
    let newFile = null;
    const newItem = {};
    try {
      if (activeTab === "resume") {
        newFile = data.resumeUpload[0];
        newItem['type'] = 'resume'
      } else if (activeTab === "coverLetter") {
        newFile = data.coverLetterUpload[0];
        newItem['type'] = 'cover'
      } else {
        newFile = data.otherdocsUpload[0];
        newItem['type'] = 'other'
      }
      const res = await handleFileUpload(newFile);

      const currentDate = new Date().toLocaleDateString();
      const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

      const sizeInKB = (newFile.size / 1024).toFixed(2);
      console.log(currentDate)
      console.log(currentTime)
      console.log(newFile.name);
      console.log(sizeInKB)
      console.log(res);
      newItem['filename'] = newFile.name
      newItem['fileId'] = res.fileIds[0];
      newItem['size'] = `${sizeInKB}kb`;
      newItem['dateTime'] = `${currentDate} ${currentTime}`;
      await handleUpdateFilesInDb(user.uid, newItem);
      toast.success("File uploaded successfully");
      reset();
      setAuthError("");
      onSaveKnowledge();
      // navigate("/dashboard");
    } catch (error) {
      // console.log(error)
      console.log(error.response.data.message);
      setAuthError(error.response.data.message);
    }
  };

  const onDeleteSubmit = async (fileId) => {
    await handleDeleteFileFromDb(user.uid, fileId)
    toast.success('File deleted successfully')
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-center z-10 h-screen">
      <div className="bg-white p-8 h-screen overflow-auto">


      <div>
        <h2 className="text-lg font-bold mb-1">Upload Knowledge</h2>
        <p className="text-sm text-gray-700">
          Upload knowledge files here, pdf, xls, txt, csv etc;
        </p>
        </div>



        <div className="p-4">
          {/* Document Type Tabs */}
          <ul className="flex border-b mt-4 hidden">
            <li className="mr-1">
              {/* Resume tab */}
              <button
                className={`py-2 px-4 rounded-t-lg inline-block ${activeTab === "resume"
                    ? "bg-blue-600 text-white"
                    : "bg-white text-blue-500 hover:bg-blue-100"
                  } focus:outline-none`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("resume");
                }}
                role="tab"
              >
                Knowledge
              </button>
            </li>
          </ul>
          {/* Tab panes */}
          <div className="p-4 py-8 rounded-md">
            {/* Content for Resume tab */}
            {activeTab === "resume" && (
              <div className="pt-4">
                <label
                  htmlFor="resumeUpload"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload Resume (PDF, DOCX up to 10MB)
                </label>
                <input
                  {...register("resumeUpload", { required: true })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-sm leading-tight focus:ring-blue-500 focus:border-blue-500 p-2"
                  type="file"
                  id="resumeUpload"
                  accept="application/pdf,.docx"
                />
                {errors.resumeUpload?.type === "required" && (
                  <p className="text-orange-600">File is required</p>
                )}

                <div className="mt-6">
                  <h2 className="font-bold text-md border-b pb-2">
                    Uploaded Documents
                  </h2>

                  <table className="min-w-full bg-white border-collapse mt-6">
                    <thead>
                      <tr>
                        <th className="py-2 px-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          File
                        </th>
                        <th className="py-2 px-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Size
                        </th>
                        <th className="py-2 px-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th className="py-2 px-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {user && !isLoading && (
                        user.files.filter((file) => file.type === 'resume').map((file, index) => (
                          <tr key={index} className="bg-gray-50 hover:bg-gray-100">
                            <td className="py-2 px-3 border-b border-gray-200 text-sm text-gray-900">
                              {file.filename}
                            </td>
                            <td className="py-2 px-3 border-b border-gray-200 text-sm text-gray-900">
                              {file.size}
                            </td>
                            <td className="py-2 px-3 border-b border-gray-200 text-sm text-gray-900">
                              {file.dateTime}
                            </td>
                            <td className="py-2 px-3 border-b border-gray-200 text-sm text-gray-900">
                              <button onClick={() => onDeleteSubmit(file.fileId)} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-500">
                                Delete File
                              </button>
                            </td>
                          </tr>
                        ))
                      )}

                    </tbody>
                  </table>
                </div>
              </div>
            )}

          </div>
        </div>

        <div className="flex justify-end mt-4 border-t pt-4">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-200 rounded"
          >
            Close
          </button>
          <button
            onClick={handleSubmit(onSubmit)}
            className="px-4 py-2 bg-blue-600 text-white rounded"
          >
            Save
          </button>
        </div>

        {authError && <p className="text-danger">{authError}</p>}
      </div>
    </div>
  );
}

export default KnowledgeModal;
