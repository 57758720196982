import React from 'react';

// Functional component to display the timer
const RecordingTimer = ({ timer }) => {
  return (
    <div className="timer-container" style={{ textAlign: 'right', marginRight: '20px' }}>
      <span className="timer-text" style={{ fontWeight: 'bold', fontSize: '16px' }}>
        Meeting Time: {timer}
      </span>
    </div>
  );
};

export default RecordingTimer;
