import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import {Spinner} from "../components"

const PrivateRoutes = () => {
  const { user, isLoading } = useSelector(
    (state) => state.user.value
  );

  if (isLoading) return <Spinner loading={isLoading} />;

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;