import React from "react";


function GivePermissionsModal({ isOpen, onClose,startScreenShare }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
      <div className="bg-white p-4 rounded-lg px-8">
        <h2 className="text-lg font-bold mb-1">Permissions</h2>
        <p className="text-sm text-gray-700">
          Let meeting copilot access your screen and audio
        </p>

        <div className="flex flex-col text-center mt-4">
          <span className="font-semibold mb-4 opacity-50">
            Choose your audio options before proceeding
          </span>
          <div className="flex flex-row items-stretch h-auto">
            <div className="grow max-w-md space-y-1 h-max list-inside border rounded-md border-gray-600">
              <div className="p-6 text-start">
                <p className="text-sm text-black font-bold mb-2 mt-4">
                  Welcome to Meeting Copilot, your real-time solution for
                  answering meeting questions.
                </p>
                <p className="text-sm text-gray-500">
                  To provide you with personalized responses, we require the
                  selection of audio sources for both you and the meeting.
                </p>
                <p className="text-sm text-black font-bold mb-2 mt-4">
                  Step 1: Enable Microphone Permission
                </p>
                <p className="text-sm text-gray-500 mt-2 inline">
                  For first time user, you will be prompted to enable audio
                  share. Click ”Allow” and refresh the page to continue
                </p>
                <br />
                <p className="inline text-sm text-black font-bold mb-2 mt-4">
                  Step 2: Set Up Computer Audio
                </p>
                <p className="text-sm text-gray-500">
                  Select the microphone you are using so Copilot can hear you
                </p>
                <p className="text-sm text-black font-bold mb-2 mt-4">
                  Step 3: Share Browser Tab
                </p>
                <p className="text-sm text-gray-500 inline">
                  Share the meeting session on your browser so Copilot can
                  hear your meeting. You must select a browser tab.
                </p>
                <a
                  href="/help/browser-tabs"
                  className="inline mt-3 text-sm underline text-black font-bold"
                  rel="noreferrer"
                >
                  Browser tab?
                </a>
                <button
                  className="text-sm underline text-black font-bold"
                  rel="noreferrer"
                >
                  Testing out Copilot? Click here to launch mock meeting video
                </button>
              </div>
              <div className="flex flex-row items-center justify-between rounded-b bg-gray-200 px-2 py-1">
                <div className="grow flex flex-row items-center justify-between">
                  <button className="text-dark bg-gray-200 border-2 rounded-md p-2 w-full">
                    Learn how to connect
                  </button>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div className="flex justify-end mt-4 border-t pt-4">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-200 rounded"
          >
            Deny
          </button>
          <button
            onClick={startScreenShare}
            className="px-4 py-2 bg-blue-600 text-white rounded"
          >
            Connect
          </button>
        </div>
      </div>
    </div>
  );
}

export default GivePermissionsModal;
